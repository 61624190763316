import React, {useEffect, useState} from 'react';
import Aux from '../../hoc/Aux/Aux';
import ProjectPlaceholder from '../../components/UI/Placeholder/ProjectPlaceholder/ProjectPlaceholder';
import Gallery from '../../components/Gallery/Gallery';
import GalleryItem from '../../components/Gallery/GalleryItem/GalleryItem';
import ShareButton from '../../components/UI/SocialShareButton/SocialShareButton';
import thumbnailPlaceholder from '../../assets/images/project-thumbnail-placeholder.webp';

import classes from "./Project.module.scss";
import {Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from 'reactstrap';
import axios from '../../axios-base';
import moment from 'moment';
import SMSVote from "./SMSVote";
import VotePlaceholder from './VotePlaceholder';
import {Helmet} from "react-helmet";
import {useParams} from 'react-router-dom';
import ProgressBar from "../../components/UI/ProgressBar/ProgressBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareAlt} from '@fortawesome/free-solid-svg-icons'
import {determineVotes} from "../../shared/VoteEvaluatorFunctions"
import Badge from "../../components/UI/Badges/Badge";


function Project(props) {

    const [loadedPost, setLoadedPost] = useState(null);
    const [voteCount, setVoteCount] = useState(null);

    let {id} = useParams();
    useEffect(() => {
        axios.get(`projects/${id}`)
            .then(({data}) => {
                let votes = determineVotes(data.voteCount, props.votingPhases);
                setLoadedPost(data)
                setVoteCount(votes);
            })
            .catch(error => {
                console.log(error);
            });
    }, [props.votingPhases, id])

    let voteComponent;
    if (loadedPost && props?.votingPhases && props.fundingStages) {

        const startDate = props.votingPhases.voteStart;
        if (!props.votingPhases.votingStarted) {
            let text = 'Am ' + moment(startDate).format('DD.MM.') + ' geht\'s los';
            voteComponent = <VotePlaceholder messageText={text} messageType='primary'/>;
        } else if (props.votingPhases.votingEnded) {
            voteComponent = <VotePlaceholder messageText='Die Abstimmung ist beendet!' messageType='primary'/>;
        } else {
            // voting in progress
            const lastFundingStage = props.fundingStages[props.fundingStages.length - 1];
            if (voteCount < lastFundingStage.threshold) {
                if (props.balance > 0) {
                    voteComponent = <SMSVote projectCategory={loadedPost.projectCategory} {...props}/>;
                } else {
                    voteComponent = <VotePlaceholder messageText='Der Fördertopf ist leer' messageType='info'/>;
                }
            } else {
                voteComponent = <VotePlaceholder
                    messageText='Die maximale Förderung ist erreicht! Geben Sie Ihre Stimme einem anderen Projekt!'
                    messageType='success' messageLink="../projekte/"/>;
            }
        }
    }

    let project = (
        <Row className="py-3">
            <Col xs="12">
                <ProjectPlaceholder/>
            </Col>
        </Row>
    );

    if (loadedPost) {

        // move socialMediaServices configuration to database so services can eventually be setup per campaign via Cockpit
        const socialMediaServices = ['whatsApp', 'instagram', 'facebook', 'facebookMessenger', 'pinterest', 'telegram', 'email', 'twitter', 'linkedin', 'xing']
        project = (
            <Aux>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PROJECT_TITLE}: ${loadedPost.contestantName}`}</title>
                </Helmet>
                <Row className="my-3">
                    <Col xs="12" lg="5">
                        <Row className="pb-3 pt-1 position-relative">
                            <Col xs="12">
                                {loadedPost && loadedPost.projectGallery.length ? <div className={classes.CardImage}
                                                                                       style={{backgroundImage: `url(${loadedPost.projectGallery[0]})`}}/> :
                                    <div className={classes.CardImage}
                                         style={{backgroundImage: `url(${thumbnailPlaceholder})`}}/>}
                            </Col>
                            <Row className="position-absolute w-100 h-100 m-0 top-0 justify-content-end">
                                <Badge type="isReferral"
                                       isAchieved={loadedPost.achievements.some(ach => ach.type === 'isReferral' && ach.isAchieved)}/>
                                <Badge type="hasReferred"
                                       isAchieved={loadedPost.achievements.some(ach => ach.type === 'hasReferred' && ach.isAchieved)}/>
                                <Badge type="whatsAppShares"
                                       isAchieved={loadedPost.achievements.some(ach => ach.type === 'whatsAppShares' && ach.isAchieved)}/>
                                <Badge type="facebookShares"
                                       isAchieved={loadedPost.achievements.some(ach => ach.type === 'facebookShares' && ach.isAchieved)}/>
                                <Badge type="emailShares"
                                       isAchieved={loadedPost.achievements.some(ach => ach.type === 'emailShares' && ach.isAchieved)}/>
                                <Badge type="twitterShares"
                                       isAchieved={loadedPost.achievements.some(ach => ach.type === 'twitterShares' && ach.isAchieved)}/>
                                {/* Add more BadgeComponent instances as needed for other badge types */}
                            </Row>
                        </Row>
                        <Row>
                            <Col>
                                <h1 className="text-primary">{loadedPost.contestantName}</h1>
                                <h2 className="text-dark">{loadedPost.projectTitle}</h2>

                            </Col>
                        </Row>
                        <Row>
                            <Col className="overflow-hidden">
                                <ProgressBar
                                    cssClasses={"mt-5 mb-4"}
                                    voteCount={voteCount}
                                    projectBalance={loadedPost ? loadedPost.fundingAccount.balance : 0}
                                    bubble={true}
                                    fundingStages={props.fundingStages}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {voteComponent}
                        </Row>
                        <Row className="mb-3">
                            <Col xs="12" lg="12">
                                <UncontrolledDropdown direction="down" className="d-grid">
                                    <DropdownToggle color="primary" outline>
                                        Mehr Stimmen sammeln? Jetzt teilen!
                                        <FontAwesomeIcon className="ps-1" icon={faShareAlt}/>
                                    </DropdownToggle>
                                    <DropdownMenu end className="mt-1 p-0"
                                                  style={{boxShadow: "0 3px 4px 0px rgb(0 0 0 / 25%)"}}>
                                        <Row>
                                            {socialMediaServices.map((service, index) => {
                                                return (
                                                    <Col xs="6" key={index}
                                                         className={`${classes.DropdownItemContainer}`}>
                                                        <DropdownItem
                                                            className="btn-light btn"
                                                            toggle={false}>
                                                            <ShareButton shareType={service}
                                                                         projectId={id}
                                                                         voteCount={voteCount}
                                                                         fundingStages={props.fundingStages}/>
                                                        </DropdownItem>
                                                        <DropdownItem divider className="m-0"/>
                                                    </Col>
                                                )
                                            })}</Row>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                        </Row>

                    </Col>

                    <Col xs="12" lg="7">
                        <Row>
                            <Col xs={{size: 12, order: 2}} lg={{order: 1}}>
                                <h1 className="text-primary">Das ist unser Zukunftsprojekt</h1>
                                <p>{loadedPost.contestantAnswer1}</p>
                            </Col>

                            <Col xs={{size: 12, order: 1}} lg={{order: 3}} className="px-0 p-md-3">
                                <Gallery>
                                    {loadedPost.projectGallery.map((galleryItem, index) => (
                                        <GalleryItem
                                            key={index}
                                            src={galleryItem}
                                            alt={'Galeriebild ' + index}/>
                                    ))}
                                </Gallery>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Aux>
        )
    }

    return (
        <Aux>
            {project}
        </Aux>
    );
}

export default Project;
