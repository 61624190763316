export const scaleVotesToProgress = (currentVote, finalThreshold) => {
    let scaledProgress = 0;
    if (finalThreshold > 0 && currentVote > 0) {
        // use value of votes to scale progress according to last threshold and progress range
        scaledProgress = (currentVote * 100 / finalThreshold);
        //if current vote is greater than last threshold.
        if (scaledProgress > 100) {
            scaledProgress = 100;
        }
    }
    return scaledProgress;
}

export const fundingMessage = (upcomingThresholdIndex, fundingStages) => {
    function formatNumber(number) {
        return new Intl.NumberFormat('de-DE').format(number);
    }

    let currentFundingGoalText = '...';
    let threshold;
    let increment;
    let totalFund = fundingStages.filter(s => upcomingThresholdIndex === -1 || s.threshold < fundingStages[upcomingThresholdIndex].threshold)
        .reduce((a, b) => a + b.increment, 0)

    if (upcomingThresholdIndex === -1) {
        //fully funded
        currentFundingGoalText =
            <small className="text-muted">Glückwunsch: {formatNumber(totalFund)} Euro Förderung
                gesichert!</small>;
    } else if (upcomingThresholdIndex === 0) {
        //Not funded yet
        threshold = fundingStages[0].threshold;
        increment = fundingStages[0].increment;
        currentFundingGoalText =
            <small className="text-muted">{formatNumber(threshold)} Stimmen knacken und {formatNumber(increment)} Euro
                sichern!</small>;
    } else {
        threshold = fundingStages[upcomingThresholdIndex].threshold;
        increment = fundingStages[upcomingThresholdIndex].increment;
        currentFundingGoalText =
            <small className="text-muted">{formatNumber(threshold)} Stimmen knacken und
                weitere {formatNumber(increment)} Euro sichern!</small>;
    }
    return currentFundingGoalText;
}

export const findNextFundingStageIndex = (voteCount, fundingStages) => {

    //todo: Since -1 means a fully funded project, an if clause to ensure that fundingStages exists is needed,
    // otherwise an empty fundingStages can also return -1. Currently this check is done in other components only.

    let upcomingThreshold = Infinity;
    let upcomingThresholdIndex = -1;

    for (let i = 0; i < fundingStages.length; i++) {
        const {threshold} = fundingStages[i];
        //find upcoming threshold
        if (threshold > voteCount && threshold < upcomingThreshold) {
            upcomingThreshold = threshold;
            upcomingThresholdIndex = i;
        }
    }
    return upcomingThresholdIndex;
}