import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faFacebookMessenger,
    faInstagram,
    faLinkedin,
    faPinterest,
    faTelegram,
    faTwitter,
    faWhatsapp,
    faXing,
} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {NavLink} from 'react-router-dom';
import axios from '../../../axios-base'
import {findNextFundingStageIndex} from "../ProgressBar/ProgressBar.helper";

const ShareButton = (props) => {
    const {voteCount, projectId, shareType} = props;
    let shareText;
    let utmSource = props.shareType;
    const utmParams = encodeURIComponent(`utm_source=${utmSource}&utm_medium=social&utm_campaign=Projektdetailsseite`);
    let shareUrl = window.location.href + (window.location.href.includes('?') ? `&${utmParams}` : `?${utmParams}`);

    let threshold;
    let increment;

    const nextStageIndex = props.fundingStages && findNextFundingStageIndex(props.voteCount, props.fundingStages);

    if (nextStageIndex === -1) {
        //fully funded
        shareText = encodeURIComponent(`Wir haben es geschafft und das Förderziel erreicht! Hier gibt es noch andere tolle Projekte denen wir helfen können ihr Förderziel zu erreichen`);
    } else {
        //Not funded yet
        threshold = props.fundingStages[nextStageIndex].threshold;
        increment = props.fundingStages[nextStageIndex].increment;
        shareText = encodeURIComponent(`Uns fehlen noch ${threshold - voteCount} Stimmen für eine Förderung von ${increment} Euro. Hilf uns das Förderungsziel zu erreichen und stimme jetzt für uns ab`);
    }

    const handleShare = () => {
        // Make a PATCH request to your backend to increment the share count
        axios.patch(`/achievements/${projectId}/${shareType}Shares`)
            .then(response => {
                // Handle success response if needed
                console.log(response.data);
            })
            .catch(error => {
                // Handle error response if needed
                console.error('Error:', error);
            });
    };

    let button;

    switch (props.shareType) {
        case 'email':
            button = (
                <a
                    rel="noreferrer"
                    href={`mailto:?subject=Jetzt für uns abstimmen!&body=${shareText}: ${shareUrl}`}
                    target="_blank"
                    onClick={handleShare}
                    className="anl_social-share--email d-flex align-items-center text-dark text-decoration-none p-1">
                    <FontAwesomeIcon color="#FFC234" fixedWidth icon={faEnvelope} size="lg"/>
                    <span className="ps-2">E-Mail</span>
                </a>
            );
            break;
        case 'facebook':
            button = (
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                    target="_blank"
                    onClick={handleShare}
                    rel="external noopener noreferrer"
                    className="anl_social-share--facebook d-flex align-items-center text-dark text-decoration-none p-1">
                    <FontAwesomeIcon color="#375797" fixedWidth icon={faFacebook} size="lg"/>
                    <span className="ps-2">Facebook</span>
                </a>
            );
            break;
        case 'twitter':
            button = (
                <a
                    href={`https://twitter.com/share?url=${shareUrl}&text=${shareText}`}
                    target="_blank"
                    onClick={handleShare}
                    rel="external noopener noreferrer"
                    className="anl_social-share--twitter d-flex align-items-center text-dark text-decoration-none p-1">
                    <FontAwesomeIcon color="#579BD1" fixedWidth icon={faTwitter} size="lg"/>
                    <span className="ps-2">Twitter</span>
                </a>
            );
            break;
        case 'whatsApp':
            button = (
                <a
                    rel="noreferrer"
                    href={`whatsapp://send?text=${shareText}%20${shareUrl}`}
                    target="_blank"
                    onClick={handleShare}
                    className="anl_social-share--whatsapp d-flex align-items-center text-dark text-decoration-none p-1">
                    <FontAwesomeIcon color="#25D366" fixedWidth icon={faWhatsapp} size="lg"/>
                    <span className="ps-2">WhatsApp</span>
                </a>
            );
            break;
        case 'facebookMessenger':
            button = (
                <a
                    rel="noreferrer"
                    href={`fb-messenger://share/?link=${shareUrl}`}
                    target="_blank"
                    onClick={handleShare}
                    className="anl_social-share--messenger d-flex align-items-center text-dark text-decoration-none p-1">
                    <FontAwesomeIcon color="#00B2FF" fixedWidth icon={faFacebookMessenger} size="lg"/>
                    <span className="ps-2">Messenger</span>
                </a>
            );
            break;
        case 'telegram':
            button = (
                <a
                    rel="noreferrer"
                    href={`https://t.me/share/url?url=${shareUrl}&text=${shareText}`}
                    target="_blank"
                    onClick={handleShare}
                    className="anl_social-share--telegram d-flex align-items-center text-dark text-decoration-none p-1">
                    <FontAwesomeIcon color="#229ED9" fixedWidth icon={faTelegram} size="lg"/>
                    <span className="ps-2">Telegram</span>
                </a>
            );
            break;
        case 'linkedin':
            button = (
                <a
                    rel="noreferrer"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
                    target="_blank"
                    onClick={handleShare}
                    className="anl_social-share--linkedin d-flex align-items-center text-dark text-decoration-none p-1">
                    <FontAwesomeIcon color="#0077B5" fixedWidth icon={faLinkedin} size="lg"/>
                    <span className="ps-2">LinkedIn</span>
                </a>
            );
            break;
        case 'xing':
            button = (
                <a
                    rel="noreferrer"
                    href={`https://www.xing.com/spi/shares/new?url=${shareUrl}`}
                    target="_blank"
                    onClick={handleShare}
                    className="anl_social-share--xing d-flex align-items-center text-dark text-decoration-none p-1">
                    <FontAwesomeIcon color="#126567" fixedWidth icon={faXing} size="lg"/>
                    <span className="ps-2">XING</span>
                </a>
            );
            break;
        case 'pinterest':
            button = (
                <a
                    rel="noreferrer"
                    href={`https://www.pinterest.com/pin/create/bookmarklet/?url=${shareUrl}`}
                    target="_blank"
                    className="anl_social-share--pinterest d-flex align-items-center text-dark text-decoration-none p-1">
                    <FontAwesomeIcon color="#e60023" fixedWidth icon={faPinterest} size="lg"/>
                    <span className="ps-2">Pinterest</span>
                </a>
            );
            break;
        case 'instagram':
            button = (
                <NavLink
                    className={`anl_social-share--instagram d-flex align-items-center text-dark text-decoration-none p-1`}
                    to="../tipps" exact>
                    <FontAwesomeIcon color="#dd2a7b" fixedWidth icon={faInstagram} size="lg"/>
                    <span className="ps-2">Instagram</span>
                </NavLink>
            );
            break;
        default:
            button = (
                <span>Kein Dienst definiert</span>
            );
            break;
    }

    return button;
};

export default ShareButton;
